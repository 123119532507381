<template>
  <v-expansion-panels class="my-6">
    <draggable
      v-model="localRuleConditions"
      @start="drag = true"
      @end="drag = false"
      class="removed-draggable-style"
    >
      <v-expansion-panel
        v-for="(rule, index) in localRuleConditions"
        :key="index"
        class="mt-4"
      >
        <RuleHeader
          :rule="rule"
          :index="index"
          :isGroupRules="false"
          @removeRuleFromList="$emit('removeRuleFromList', rule)"
          @switchRule="$emit('switchRule', rule)"
        />
        <v-expansion-panel-content v-if="rule.conditionType != 23">
          <component
            :is="ruleComponent(rule.conditionType)"
            :values="rule.values"
            @updateConditionValues="$emit('updateConditionValues', $event)"
          ></component>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </draggable>
  </v-expansion-panels>
</template>

<script>
import RuleHeader from "../../../../group-rule-header.vue";
import draggable from "vuedraggable";
import billingProfitVue from "./rules-component/billing-profit.vue";
import centsRoundedVue from "./rules-component/cents-rounded.vue";
import competitiveIndexVue from "./rules-component/competitive-index.vue";
import contribuitionLimitVue from "./rules-component/contribuition-limit.vue";
import demandVariationVue from "./rules-component/demand-variation.vue";
import incrementDecrementPriceVue from "./rules-component/increment-decrement-price.vue";
import lastPriceVue from "./rules-component/last-price.vue";
import limitPriceByCompetitorVue from "./rules-component/limit-price-by-competitor.vue";
import limitWeekDaysVue from "./rules-component/limit-week-days.vue";
import profitVariationVue from "./rules-component/profit-variation.vue";
import psycologicPriceLastCentVue from "./rules-component/psycologic-price-lastCent.vue";
import psycologicPriceVue from "./rules-component/psycologic-price/psycologic-price.vue";
import psycologicPrice09Vue from "./rules-component/psycologic-price09.vue";
import psycologicPrice10Vue from "./rules-component/psycologic-price10.vue";
import psycologicPrice50Vue from "./rules-component/psycologic-price50.vue";
import psycologicPrice90Vue from "./rules-component/psycologic-price90.vue";
import psycologicPrice99Vue from "./rules-component/psycologic-price99.vue";
import stockDemandVue from "./rules-component/stock-demand.vue";
import useCompetitorPriceVue from "./rules-component/use-competitor-price.vue";
import variationSalePriceVue from "./rules-component/variation-sale-price.vue";
import contribuitionLimiNetworktVue from "./rules-component/contribuition-limit-network.vue";
import priceLimitVue from "./rules-component/price-limit.vue";
import priceMultiplier from "./rules-component/price-multiplier.vue";
import captainProduct from "./rules-component/captain-product.vue";
import spinLimit from "./rules-component/spin-limit.vue";
import stockMin from "./rules-component/stock-min.vue";
export default {
  components: { RuleHeader, draggable },

  props: {
    ruleConditions: {
      type: Array,
    },
  },

  computed: {
    localRuleConditions: {
      get() {
        return this.ruleConditions;
      },
      set(value) {
        this.$emit("handleChangeRulePosition", value);
      },
    },
  },

  methods: {
    ruleComponent(conditionType) {
      switch (conditionType) {
        case 0:
          return limitWeekDaysVue;
        case 1:
          return limitPriceByCompetitorVue;
        case 2:
          return contribuitionLimitVue;
        case 3:
          return incrementDecrementPriceVue;
        case 4:
          return useCompetitorPriceVue;
        case 6:
          return psycologicPriceVue;
        case 7:
          return psycologicPrice09Vue;
        case 8:
          return psycologicPrice99Vue;
        case 9:
          return psycologicPrice50Vue;
        case 10:
          return lastPriceVue;
        case 11:
          return psycologicPrice90Vue;
        case 12:
          return psycologicPrice10Vue;
        case 13:
          return psycologicPriceLastCentVue;
        case 14:
          return profitVariationVue;
        case 15:
          return competitiveIndexVue;
        case 16:
          return centsRoundedVue;
        case 17:
          return demandVariationVue;
        case 18:
          return stockDemandVue;
        case 19:
          return variationSalePriceVue;
        case 20:
          return billingProfitVue;
        case 21:
          return contribuitionLimiNetworktVue;
        case 22:
          return priceLimitVue;
        case 23:
          return priceMultiplier;
        case 24:
          return stockMin;
        case 25:
          return spinLimit;
        case 26:
          return captainProduct;
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.removed-draggable-style {
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
}
</style>
