<template>
  <div>
    <Prd-loading-msg class="mt-4" v-show="someLoading" :text="loadingText" />
    <div v-show="!someLoading">
      <h4 v-if="idRule == 0" class="mb-4 pt-4">
        {{ $t("TXT_ADD_RULE").toUpperCase() }}
      </h4>

      <div>
        <Prd-text-field
          v-model="localDescription"
          :title="$t('TXT_DESCRIPTION')"
          :placeholder="$t('TXT_INSERT_HERE_RULE')"
          hide-details
        />
        <div class="d-flex align-center gap-4">
          <v-checkbox
            v-model="localIsAllProducts"
            :label="$t('TXT_SELECT_ALL_PRODUCTS_AND_CATEGORIES')"
            class="ma-0 pa-0 mt-4"
            hide-details
          />
          <v-checkbox
            v-model="allowMultipleBranchs"
            :label="$t('TXT_ALLOW_TO_MULTIPLE_BRANCHS')"
            :disabled="filtersLoading || (idRule != 0 && allowMultipleBranchs)"
            class="ma-0 pa-0 mt-4"
            hide-details
          />
        </div>
      </div>

      <Filters-area
        :loadingRules="loadingRules"
        :idRule="parseInt(idRule)"
        :rule="ruleForFieldArea"
        :resetFieldsFlag="localResetFieldsFlag"
        :enableMultGroups="enableMultGroups"
        @elasticity="$emit('updateElasticity', $event)"
        @filters="$emit('updateFilters', $event)"
        @curve="$emit('updateCurve', $event)"
        @setLoadingFilters="filtersLoading = $event"
      />

      <Branch-Tabs-Main :idRule="idRule" :enableMultGroups="enableMultGroups" />

      <v-row no-gutters justify="end">
        <Prd-btn
          :title="$t('TXT_CANCEL')"
          :outlined="true"
          class="mr-4"
          @click="cancelEditon"
        />
        <Prd-btn
          :title="textButton"
          :disabled="!localDescription || disableRulesActions"
          @click="$emit('saveRules')"
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import FiltersArea from "./components/filters-area.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import BranchTabsMain from "./components/branch-tabs/branch-tabs-main.vue";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import HandlerRules from "@/service/pricing-rules/create-rules.js";

const handlerRules = new HandlerRules();
export default {
  components: {
    PrdTextField,
    FiltersArea,
    PrdBtn,
    PrdLoadingMsg,
    BranchTabsMain,
  },

  props: {
    idRule: {
      type: Number,
      default: 0,
    },
    resetFieldsFlag: {
      type: Boolean,
      default: false,
    },
    loadingRules: {
      type: Boolean,
      default: false,
    },
    loadingSavingChanges: {
      type: Boolean,
      default: false,
    },
    ruleForFieldArea: {
      type: Object,
      default: () => ({}),
    },
    description: {
      type: String,
      default: "",
    },
    isAllProducts: {
      type: Boolean,
      default: false,
    },
    enableMultGroups: {
      type: Boolean,
      default: false,
    },
    childrens: {
      type: Array,
    },
  },

  data() {
    return {
      localDescription: null,
      localIsAllProducts: false,
      localNumberOrder: 0,
      localResetFieldsFlag: false,
      filtersLoading: true,
      request: {
        idRule: 220804,
        children: [
          {
            idRule: 220805,
            conditions: [
              {
                conditionType: 24,
                idCondition: 1258685,
                values: [
                  {
                    idConditionValue: 4850042,
                  },
                  {
                    idConditionValue: 4850043,
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    textButton() {
      return this.idRule
        ? this.$i18n.t("TXT_SAVE_EDITIONS")
        : this.$i18n.t("TXT_ADD_RULE");
    },

    loadingText() {
      return this.$i18n.t(
        !this.loadingSavingChanges && this.loadingRules
          ? "TXT_LOADING_DATA_RULE_GROUP"
          : this.loadingSavingChanges && !this.loadingRules
          ? "TXT_SAVING"
          : "TXT_LOADING_DATA"
      );
    },

    someLoading() {
      return this.loadingRules || this.loadingSavingChanges;
    },

    disableRulesActions() {
      return this.$store?.getters?.disableRulesActions;
    },

    allowMultipleBranchs: {
      get() {
        return this.idRule != 0
          ? this.enableMultGroups
          : this.$store.state.pricingRule.allowMultipleBranchs;
      },
      set(value) {
        this.$store.dispatch("setAllowMultipleBranchs", value);
      },
    },
  },

  watch: {
    resetFieldsFlag: {
      handler(value) {
        this.localResetFieldsFlag = value;
      },
      immediate: true,
    },
    localResetFieldsFlag: {
      handler(value) {
        this.$emit("updateResetFieldsFlag", value);
      },
      immediate: true,
    },
    description: {
      handler(value) {
        this.localDescription = value;
      },
      immediate: true,
    },
    localDescription: {
      handler(value) {
        this.$emit("updateDescription", value);
      },
      immediate: true,
    },
    isAllProducts: {
      handler(value) {
        this.localIsAllProducts = value;
      },
      immediate: true,
    },
    localIsAllProducts: {
      handler(value) {
        this.$emit("updateIsAllProducts", value);
      },
      immediate: true,
    },
    filtersLoading: {
      handler(value) {
        if (!value && this.childrens) {
          this.$store.commit("setBranchsToEdit2", {
            childrens: this.childrens,
            isEdit: true,
          });
        }
      },
    },
    loadingRules: {
      handler(value) {
        if (!value && this.childrens) {
          this.$store.commit("setBranchsToEdit2", {
            childrens: this.childrens,
            isEdit: true,
          });
        }
      },
    },
  },

  methods: {
    cancelEditon() {
      if (this.idRule == 0) {
        this.localResetFieldsFlag = !this.localResetFieldsFlag;
        this.$router.push("/main/pricing-rules");
      } else {
        this.$emit("closePanel");
      }
    },

    async getCompetitors() {
      this.$store.commit("updateSellerListLoading", true);

      try {
        const response = await handlerRules.getCompetitors(this.idCompany);
        this.$store.commit("updateSellerList", response.data.result);
      } catch (error) {
        if (
          error?.response?.data?.message ==
            "A sequência não contém elementos" ||
          error?.response?.data?.message == "Sequence contains no elements"
        )
          return;
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR"),
          type: "error",
        });
        this.$store.commit("updateSellerListLoading", false);
      }

      this.$store.commit("updateSellerListLoading", false);
    },
  },

  created() {
    this.getCompetitors();
  },
};
</script>
