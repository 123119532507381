<template>
  <v-row no-gutters>
    <Prd-text-field
      v-model="model.Value"
      :title="$t('TXT_LIMIT')"
      type="number"
      hide-spin-buttons
    />
  </v-row>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";

export default {
  components: {
    PrdTextField,
  },
  props: ["index", "values", "idRule","branch"],
  data() {
    return {
      model: {
        Value: 0,
      },
    };
  },
  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    values: {
      handler(value) {
        if (value.values?.length > 0) this.setInputs(value.values);
      },
      immediate: true,
    },
  },
  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "Value",
          decimalValue: inputs.Value ? parseFloat(inputs.Value) : 0,
          booleanValue: false,
        },
      ];
      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updateConditionsDefault",
        conditionType: 25,
        index: this.index,
        values: formatedValue,
        branch:this.branch

      });
    },
    setInputs(conditionList) {
      const Value = conditionList.find(
        (condition) => condition.stringValue == "Value"
      ).decimalValue;
      this.model.Value = Value ?? 0;
    },
  },
};
</script>
