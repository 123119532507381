<template>
  <v-row no-gutters class="gap-4">
    <Prd-combo-box
      v-model="model.Operator"
      :title="$t('TXT_REFERENCE')"
      :items="operatorItems"
    />
    <Prd-text-field
      v-model="model.Value"
      :title="$t('TXT_STOCK')"
      type="number"
      hide-spin-buttons
    />
  </v-row>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
export default {
  components: {
    PrdTextField,
    PrdComboBox,
  },
  props: ["index", "values", "idRule","branch"],
  data() {
    return {
      model: {
        Value: 0,
        Operator: null,
      },
      operatorItems: [
        { text: this.$i18n.t("TXT_LESS_THAN"), value: 0 },
        { text: this.$i18n.t("TXT_LESS_THAN_OR_EQUAL"), value: 1 },
        { text: this.$i18n.t("TXT_EQUAL_TO"), value: 2 },
        { text: this.$i18n.t("TXT_BIGGER_THAN_OR_EQUAL"), value: 3 },
        { text: this.$i18n.t("TXT_BIGGER_THAN"), value: 4 },
      ],
    };
  },
  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    values: {
      handler(value) {
        if (value.values?.length > 0) this.setInputs(value.values);
        else
          this.model.Operator = {
            text: this.$i18n.t("TXT_LESS_THAN"),
            value: 0,
          };
      },
      immediate: true,
    },
  },
  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "Value",
          decimalValue: parseFloat(inputs.Value ?? 0),
          booleanValue: false,
        },
        {
          stringValue: "Operator",
          decimalValue: inputs.Operator.value,
          booleanValue: false,
        },
      ];

      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updateConditionsDefault",
        conditionType: 24,
        index: this.index,
        values: formatedValue,
        branch:this.branch

      });
    },
    setInputs(conditionList) {
      const Value = conditionList.find(
        (condition) => condition.stringValue == "Value"
      ).decimalValue;
      this.model.Value = Value ?? 0;

      const Operator = conditionList.find(
        (condition) => condition.stringValue == "Operator"
      );
      this.model.Operator = this.operatorItems.find(
        (item) => item.value == Operator.decimalValue
      );
    },
  },
};
</script>
