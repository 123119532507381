<template>
  <v-expansion-panel class="ma-0 pa-0">
    <v-expansion-panel-header class="ma-0 pa-0 pr-4" color="#f5f5f5">
      <div class="item px-4">
        <div class="flex-center">
          <v-icon class="menu-drag handle mr-4">mdi-menu</v-icon>
          <v-switch
            v-model="rule.isActive"
            @click.stop
            dense
            flat
            class="mr-4">
          </v-switch>
          <span class="font-weight-bold mr-6">
            {{ index + 1 }} - {{ setRuleName(rule.conditionType) }}
          </span>
        </div>

        <div class="flex-center">
          <div
            class="rules-status mr-8"
            :class="rule.isActive ? 'active-color' : 'inactive-color'"
          >
            {{ status }}
          </div>

          <v-icon
            @click.stop="deleteItem"
            :color="$prdStyles('color-warning')"
            size="26px"
          >
            mdi-delete-outline
          </v-icon>
        </div>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content v-if="rule.conditionType != 23">
      <component
        :is="ruleComponent"
        :index="index"
        :values="item"
        :idRule="idRule"
        :branch="branch"
      ></component>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import billingProfitVue from "./rule-type/billing-profit.vue";
import centsRoundedVue from "./rule-type/cents-rounded.vue";
import competitiveIndexVue from "./rule-type/competitive-index.vue";
import contribuitionLimitVue from "./rule-type/contribuition-limit.vue";
import demandVariationVue from "./rule-type/demand-variation.vue";
import incrementDecrementPriceVue from "./rule-type/increment-decrement-price.vue";
import lastPriceVue from "./rule-type/last-price.vue";
import limitPriceByCompetitorVue from "./rule-type/limit-price-by-competitor.vue";
import limitWeekDaysVue from "./rule-type/limit-week-days.vue";
import profitVariationVue from "./rule-type/profit-variation.vue";
import psycologicPriceLastCentVue from "./rule-type/psycologic-price-lastCent.vue";
import psycologicPriceVue from "./rule-type/psycologic-price/psycologic-price.vue";
import psycologicPrice09Vue from "./rule-type/psycologic-price09.vue";
import psycologicPrice10Vue from "./rule-type/psycologic-price10.vue";
import psycologicPrice50Vue from "./rule-type/psycologic-price50.vue";
import psycologicPrice90Vue from "./rule-type/psycologic-price90.vue";
import psycologicPrice99Vue from "./rule-type/psycologic-price99.vue";
import stockDemandVue from "./rule-type/stock-demand.vue";
import useCompetitorPriceVue from "./rule-type/use-competitor-price.vue";
import variationSalePriceVue from "./rule-type/variation-sale-price.vue";
import contribuitionLimiNetworktVue from "./rule-type/contribuition-limit-network.vue";
import priceLimitVue from "./rule-type/price-limit.vue";
import priceMultiplier from "./rule-type/price-multiplier.vue";
import captainProduct from "./rule-type/captain-product.vue";
import spinLimit from "./rule-type/spin-limit.vue";
import stockMin from "./rule-type/stock-min.vue";

export default {
  props: ["item", "index", "idRule","branch"],
  data() {
    return {
      rule: null,
    };
  },

  computed: {
    status() {
      if (this.rule.isActive) return this.$i18n.t("TXT_ACTIVE");
      return this.$i18n.t("TXT_INACTIVE");
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    ruleComponent() {
      switch (this.rule.conditionType) {
        case 0:
          return limitWeekDaysVue;
        case 1:
          return limitPriceByCompetitorVue;
        case 2:
          return contribuitionLimitVue;
        case 3:
          return incrementDecrementPriceVue;
        case 4:
          return useCompetitorPriceVue;
        case 6:
          return psycologicPriceVue;
        case 7:
          return psycologicPrice09Vue;
        case 8:
          return psycologicPrice99Vue;
        case 9:
          return psycologicPrice50Vue;
        case 10:
          return lastPriceVue;
        case 11:
          return psycologicPrice90Vue;
        case 12:
          return psycologicPrice10Vue;
        case 13:
          return psycologicPriceLastCentVue;
        case 14:
          return profitVariationVue;
        case 15:
          return competitiveIndexVue;
        case 16:
          return centsRoundedVue;
        case 17:
          return demandVariationVue;
        case 18:
          return stockDemandVue;
        case 19:
          return variationSalePriceVue;
        case 20:
          return billingProfitVue;
        case 21:
          return contribuitionLimiNetworktVue;
        case 22:
          return priceLimitVue;
        case 23:
          return priceMultiplier;
        case 24:
          return stockMin;
        case 25:
          return spinLimit;
        case 26:
          return captainProduct;
        default:
          return null;
      }
    },
  },

  watch: {
    item: {
      handler(value) {
        this.rule = value;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    deleteItem() {
      this.$emit("deleteitem", this.index);
    },

    setRuleName(idCondition) {
      const rules = this.$store.getters.getNameAndConditionType;

      let foundedRule = rules.find((rule) => rule.conditionType == idCondition);

      return foundedRule?.text ?? "-";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .menu-drag {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  .rules-status {
    display: flex;
    width: 70px;
    padding: 4px 22px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: white;
    border-radius: 4px;
  }

  .active-color {
    background-color: #1d8527;
  }

  .inactive-color {
    background-color: #bab8b8;
  }
}
</style>
