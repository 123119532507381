<template>
  <div>
    <div class="mt-8 mb-4">
      <v-tabs
        v-model="tab"
        :slider-color="$prdStyles('color-secundary')"
        color="black"
        height="40px"
        class="border-destaq"
      >
        <v-tab class="pl-0">
          <span class="mr-2 font-weight-bold">Parametrizações</span>

          <div v-if="allowMultipleBranchs" class="d-flex align-center gap-2">
            <span>(Todas filiais)</span>
            <Add-branchs-modal :branchList="branchList" />
          </div>
        </v-tab>
        <v-tab v-for="(branch, index) in selectedBranchsToEdit" :key="index">
          Filial {{ branch.description }} |
        </v-tab>
      </v-tabs>
    </div>

    <v-tabs-items v-model="tab">
      <!-- PRINCIPAL RULES -->
      <v-tab-item>
        <Param-rules :idRule="idRule" />
      </v-tab-item>

      <!-- BRANCH RULES -->
      <v-tab-item v-for="(branch, index) in selectedBranchsToEdit" :key="index">
        <Param-rules :branch="branch" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ParamRules from "../param-rules.vue";
import AddBranchsModal from "./add-branchs-modal.vue";
export default {
  components: { ParamRules, AddBranchsModal },
  props: {
    idRule: {
      type: Number,
      default: 0,
    },
    enableMultGroups: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    tab: 0,
  }),

  computed: {
    branchList() {
      return this.$store.state.pricingRule.branchList;
    },

    selectedBranchsToEdit() {
      return this.$store.getters.selectedBranchsToEdit;
    },

    allowMultipleBranchs() {
      return this.idRule != 0
        ? this.enableMultGroups
        : this.$store.state.pricingRule.allowMultipleBranchs;
    },
  },
};
</script>

<style lang="scss" scoped>
.border-destaq {
  border-bottom: 0.5px solid rgba(59, 58, 58, 0.185);
}

.v-tab {
  font-size: 14px !important;
  text-transform: capitalize;
}
</style>
