<template>
  <v-dialog v-model="isOpen" persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <prd-btn
        :title="$t('TXT_FILE_IMPORT')"
        :leftIcon="true"
        :icon="'mdi-download-outline'"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-card class="pa-4">
      <h3 class="mb-6">{{ $t("TXT_FILE_IMPORT").toUpperCase() }}</h3>
      <p class="mb-4">{{ $t("TXT_SELECT_FILE_TO_IMPORT") }}</p>
      <v-row no-gutters align="center" class="mb-6">
        <prd-btn
          :title="$t('TXT_SELECT_FILE')"
          :leftIcon="true"
          :icon="'mdi-download-outline'"
          @click="openFileInput"
          :disabled="isLoading"
          class="mr-4"
        />
        <v-icon v-if="this.selectedFile" class="grayText mr-1"
          >mdi-paperclip</v-icon
        >
        <span class="grayText">{{ fileName }}</span>
      </v-row>
      <v-row no-gutters justify="end" class="gap-6">
        <prd-btn
          :title="$t('TXT_CANCEL')"
          :outlined="true"
          @click="cancelChangeFile"
          :disabled="isLoading"
        />
        <prd-btn
          :title="$t('TXT_TO_IMPORT')"
          @click="importConditionRounding"
          :disabled="this.selectedFile ? false : true"
          :loading="isLoading"
        />
      </v-row>
      <input
        type="file"
        id="fileInput"
        ref="fileInput"
        @change="handleFileChange($event)"
        style="display: none"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/pricing-rules/index.js";
const Service = new service();
export default {
  props: ["index", "values", "idRule", "branch"],
  components: { prdBtn },
  data() {
    return {
      selectedFile: null,
      isOpen: false,
      isLoading: false,
    };
  },
  computed: {
    fileName() {
      return this.selectedFile?.name ?? this.$i18n.t("TXT_NO_SELECTED_FILE");
    },
  },
  methods: {
    checkFileType(file) {
      const [, extension] = file.name.split(".");
      return extension == "xlsx" || extension == "csv";
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;
      if (!this.checkFileType(file)) {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_TYPE_FILE_IMPORT"),
          type: "info",
        });
      } else {
        this.selectedFile = file;
      }
    },
    setFile() {
      this.$emit("file", this.selectedFile);
      this.cancelChangeFile();
    },
    cancelChangeFile() {
      this.selectedFile = null;
      this.isOpen = false;
    },

    async importConditionRounding() {
      this.isLoading = true;
      try {
        const response = await Service.importConditionRounding(
          this.selectedFile
        );
        this.prepareNewValues(response.data.result);
        this.setFile();
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_IMPORT_FILE_ERROR"),
          type: "error",
        });
      }
      this.isLoading = false;
    },
    bodyToList(index, indexStringValue, value) {
      const stringValueList = ["_MinRange", "_MaxRange", "_SetValue"];
      return {
        idConditionValue: this.generateRandomId(),
        stringValue: `${index}${stringValueList[indexStringValue]}`,
        decimalValue: value,
        booleanValue: false,
        isDeleted: false,
      };
    },
    generateRandomId() {
      const idLength = 8;
      const characters = "0123456789";
      let randomId = "";

      for (let i = 0; i < idLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomId += characters.charAt(randomIndex);
      }

      return randomId;
    },
    prepareNewValues(list) {
      let newList = [];
      list.forEach((el, index) => {
        const keys = Object.keys(el);
        keys.forEach((item, indexEl) => {
          const newEl = this.bodyToList(index, indexEl, el[item]);
          newList.push(newEl);
        });
      });
      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updatePsycologicPriceConditions",
        conditionType: 6,
        index: this.index,
        values: newList,
        branch: this.branch,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.grayText {
  color: $neutral-color-low-light;
}
</style>
