<template>
  <div>
    <v-expansion-panel>
      <Group-Rule-Header
        :rule="rule"
        :index="index"
        :isLoading="isLoading"
        @switchRule="switchRule"
        @duplicateRule="duplicateRule"
      />

      <v-expansion-panel-content
        :loading="true"
        style="border-left: 5px solid #3c5ca7"
      >
        <Group-rule-content-main :rule="rule" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
import service from "@/service/pricing-rules/index.js";
const Service = new service();
import GroupRuleHeader from "./group-rule-header.vue";
import GroupRuleContentMain from "./rule-content/group-rule-content-main.vue";
export default {
  components: { GroupRuleHeader, GroupRuleContentMain },

  props: {
    rule: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },

  data: () => ({
    isLoading: false,
  }),

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    async switchRule() {
      this.isLoading = true;

      try {
        await Service.switchRule(this.idCompany, this.rule?.idRule);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_CHANGE_RULE_ORDER_SUCCESS"),
          type: "success",
        });
        this.$store.commit("updateRuleStatus", this.rule?.idRule);
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_CHANGE_RULE_ORDER_ERROR"));
      }

      this.isLoading = false;
    },

    async duplicateRule() {
      this.isLoading = true;

      try {
        await Service.cloneRule(this.idCompany, this.rule?.idRule);
        this.$store.dispatch("getRules");
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_DUPLICATED_RULES_ERROR"));
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.rules-status {
  display: flex;
  width: 70px;
  padding: 4px 22px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: white;
  border-radius: 4px;
}
.active-color {
  background-color: #1d8527;
}

.inactive-color {
  background-color: #bab8b8;
}
</style>
