<template>
  <div>
    <v-tabs
      v-model="tab"
      :slider-color="$prdStyles('color-secundary')"
      color="black"
      height="40px"
      class="border-destaq"
    >
      <v-tab class="pl-0">
        <span class="mr-2 font-weight-bold">{{
          $i18n.t("TXT_PARAMETERIZATIONS")
        }}</span>

        <div v-if="rule.enableMultGroups" class="d-flex align-center gap-2">
          <span>{{ $i18n.t("TXT_ALL_BRANCHS") }}</span>
          <Add-branchs-modal
            :rule="rule"
            @handleChooseBranchs="$emit('handleChooseBranchs', $event)"
          />
        </div>
      </v-tab>

      <v-tab
        v-model="tab"
        v-for="(branch, index) in rule.children"
        :key="index"
      >
        {{ branch.filters[0].value }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- Default rule -->
      <v-tab-item>
        <Add-rules-main
          :rule="rule"
          :ruleList="ruleList"
          @addRule="$emit('addRule', $event)"
          @updateConditionValues="$emit('updateConditionValues', $event)"
          @removeRuleFromList="removeRuleFromList"
          @switchRule="switchRule"
          @handleChangeRulePosition="$emit('handleChangeRulePosition', $event)"
        />
      </v-tab-item>

      <!-- Branch rules -->
      <v-tab-item v-for="(branch, index) in rule.children" :key="index">
        <Add-rules-main
          :rule="rule"
          :ruleList="ruleList"
          :branch="branch"
          @addRule="addRule"
          @updateConditionValues="$emit('updateConditionValues', $event)"
          @removeRuleFromList="removeRuleFromList"
          @switchRule="switchRule"
          @handleChangeRulePosition="handleChangeRulePosition"
          @removeBranch="$emit('removeBranch', $event)"
          @replicateBranch="$emit('replicateBranch', $event)"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AddBranchsModal from "./components/add-branchs-modal.vue";
import AddRulesMain from "./components/add-rules-main.vue";

export default {
  components: { AddBranchsModal, AddRulesMain },

  props: {
    rule: {
      type: Object,
    },
    ruleList: {
      type: Array,
    },
  },

  data: () => ({
    tab: 0,
  }),

  methods: {
    addRule(conditions, branch) {
      this.$emit("addRule", conditions, branch);
    },
    removeRuleFromList(rule, branch) {
      this.$emit("removeRuleFromList", rule, branch);
    },
    switchRule(rule, branch) {
      this.$emit("switchRule", rule, branch);
    },
    handleChangeRulePosition(rule, branch) {
      this.$emit("handleChangeRulePosition", rule, branch);
    },
  },
};
</script>

<style lang="scss" scoped>
.border-destaq {
  border-bottom: 0.5px solid rgba(59, 58, 58, 0.185);
}

.v-tab {
  font-size: 14px !important;
  text-transform: capitalize;
}
</style>
