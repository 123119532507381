<template>
  <div>
    <v-row no-gutters class="my-4">
      <v-col cols="12" md="3"
        ><Prd-text-field
          v-model="model.MinMarginNetwork"
          :title="$t('TXT_MIN_MARGIN')"
          :hasTooltip="true"
          :tooltipText="$t('TXT_TOOLTIP_MIN_MARGIN')"
          :type="'number'"
          prefix="%"
          hide-spin-buttons
          class="mr-4"
      /></v-col>
    </v-row>

    <v-checkbox
      v-model="model.AdjustMarginNetwork"
      :label="$t('TXT_AUTO_READJUST')"
      dense
      hide-details
      class="ma-0 pa-0"
    ></v-checkbox>

    <div class="price-warning">
      <v-icon :color="$prdStyles('color-warning')" left
        >mdi-alert-outline</v-icon
      >
      <span>
        {{ $t("TXT_MARGIN_PER_NETWORK_IMPORTANT") }}
      </span>
    </div>
  </div>
</template>
  
  <script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  props: ["index", "values", "idRule","branch"],
  components: { PrdTextField },
  data() {
    return {
      model: {
        MinMarginNetwork: null,
        AdjustMarginNetwork: false,
      },
    };
  },
  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    values: {
      handler(value) {
        if (value.values?.length > 0) this.setInputs(value.values);
      },
      immediate: true,
    },
  },
  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "MinMarginNetwork",
          decimalValue: inputs.MinMarginNetwork
            ? parseFloat(inputs.MinMarginNetwork)
            : 0,
          booleanValue: false,
        },
        {
          stringValue: "AdjustMarginNetwork",
          decimalValue: 0,
          booleanValue: inputs.AdjustMarginNetwork,
        },
      ];
      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updateConditionsDefault",
        conditionType: 21,
        index: this.index,
        values: formatedValue,
        branch: this.branch
      });
    },
    setInputs(conditionList) {
      const MinMarginNetwork = conditionList.find(
        (condition) => condition.stringValue == "MinMarginNetwork"
      ).decimalValue;
      this.model.MinMarginNetwork = MinMarginNetwork;

      const AdjustMarginNetwork = conditionList.find(
        (condition) => condition.stringValue == "AdjustMarginNetwork"
      ).booleanValue;
      this.model.AdjustMarginNetwork = AdjustMarginNetwork;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.price-warning {
  margin-top: 16px;
  text-align: center;
  padding: 8px;
  border: 1px solid #b00020;
  border-radius: 5px;
}
</style>