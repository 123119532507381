<template>
  <div>
    <v-row no-gutters class="my-4">
      <v-col cols="12" md="3"
        ><Prd-text-field
          v-model="model.MinVariation"
          :title="$t('TXT_MIN_VARIATION')"
          :type="'number'"
          suffix="%"
          hide-spin-buttons
      /></v-col>
    </v-row>
    <v-checkbox
      v-model="model.AdjustPrice"
      :label="$t('TXT_ADJUST_PRICE')"
      hide-details
      dense
      class="ma-0 pa-0"
    ></v-checkbox>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  props: ["index", "values", "idRule", "branch"],
  components: { PrdTextField },
  data() {
    return {
      model: {
        AdjustPrice: false,
        MinVariation: null,
      },
    };
  },

  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    values: {
      handler(value) {
        if (value.values?.length > 0) this.setInputs(value.values);
      },
      immediate: true,
    },
  },

  methods: {
    removeFalseExpressions() {
      this.model.MinVariation = this.model.MinVariation.replace(/[^\d]/g, "");
    },

    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "AdjustPrice",
          decimalValue: 0,
          booleanValue: inputs.AdjustPrice,
        },
        {
          stringValue: "MinVariation",
          decimalValue: parseFloat(inputs.MinVariation) ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "InvertBlock",
          decimalValue: 0,
          booleanValue: false,
        },
      ];
      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updateConditionsDefault",
        conditionType: 14,
        index: this.index,
        values: formatedValue,
        branch: this.branch,
      });
    },

    setInputs(conditionList) {
      const AdjustPrice = conditionList.find(
        (condition) => condition.stringValue == "AdjustPrice"
      ).booleanValue;
      this.model.AdjustPrice = AdjustPrice;

      const MinVariation = conditionList.find(
        (condition) => condition.stringValue == "MinVariation"
      ).decimalValue;
      this.model.MinVariation = MinVariation;
    },
  },
};
</script>
