<template>
  <div>
    <v-row no-gutters v-for="(itemList, index) in reorganizedList" :key="index">
      <v-col cols="3" v-for="(item, index) in itemList" :key="index">
        <v-row no-gutters align="center" justify="center" class="bottomBorderPrd">
          <Prd-text-field v-model="item.decimalValue" :type="'number'" :min="0" @input="setValuesInOriginalList(item)"
            hide-spin-buttons style="width: 100px" />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  components: { PrdTextField },
  props: ["originalList", "decimalValue"],
  data() {
    return {
      reorganizedList: [],
      localList: []
    };
  },

  watch: {
    originalList: {
      handler(value) {
        this.localList = JSON.parse(JSON.stringify(value));

        this.runReorganizeList(this.localList, this.decimalValue);

      },
      deep: true,
      immediate: true,
    },

    decimalValue() {
      if(this.decimalValue < 0) return 
      
      this.runReorganizeList(this.localList, this.decimalValue);
    },
  },

  methods: {
    runReorganizeList(list, decimals) {
      const newList = [];

      list.forEach((el) => {
        const index = el.stringValue.split("_")[0];

        const elementCopy = { ...el };
        elementCopy.decimalValue = parseFloat(elementCopy.decimalValue.toFixed(decimals));

        if (!newList[index]) newList[index] = [];
        newList[index].push(elementCopy);
      });

      this.reorganizedList = newList;
    },


    setValuesInOriginalList(item) {
      const body = {
        id: item.idConditionValue,
        value: item.decimalValue,
      };

      this.$emit("updatePsycologicPriceConditions", body);
    },
  },

  created() {
    this.localList = JSON.parse(JSON.stringify(this.originalList));

    this.runReorganizeList(this.localList, 2);
  }
};
</script>

<style lang="scss" scoped>
.bottomBorderPrd {
  border-bottom: 1px solid $neutral-color-high-medium;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
</style>
