<template>
  <div>
    <h5 class="my-4">{{ $t("TXT_SELECT_WEEK_DAYS") }}</h5>
    <div class="flex-center">
      <v-checkbox
        v-for="(day, index) in days"
        v-model="day.selected"
        :key="index"
        :label="day.text"
        dense
        hide-details
        class="ma-0 pa-0 mr-4"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: ["values"],
  data() {
    return {
      days: [
        {
          text: this.$i18n.t("TXT_WEEKDAY_SUNDAY"),
          value: "Domingo",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_MONDAY"),
          value: "Segunda-Feira",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_TUESDAY"),
          value: "Terça-Feira",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_WEDNESDAY"),
          value: "Quarta-Feira",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_THURSDAY"),
          value: "Quinta-Feira",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_FRIDAY"),
          value: "Sexta-Feira",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_SATURDAY"),
          value: "Sábado",
          selected: false,
        },
      ],
      ruleCondition: null,
    };
  },
  watch: {
    days: {
      handler(newDays) {
        let formatedValue = [];

        const selectedDays = newDays
          .filter((day) => day.selected)
          .map((day) => day.value);

        selectedDays.forEach((el) => {
          formatedValue.push({
            idConditionValue: 0,
            idRuleCondition: 0,
            stringValue: el,
          });
        });

        this.$emit("updateConditionValues", {
          formatedValue,
          conditionType: 0,
        });
      },
      deep: true,
    },
  },

  methods: {
    fillInputs() {
      this.days.forEach((day) => {
        day.selected = this.ruleCondition.some(
          (input) => input.stringValue === day.value
        );
      });
    },
  },

  created() {
    if (this.values) {
      this.ruleCondition = this.values;
      this.fillInputs();
    }
  },
};
</script>
