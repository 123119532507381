import i18n from "@/i18n";
import service from "@/service/pricing-rules/index.js";
const Service = new service();
import { compareRuleList, changedRuleIds } from "./copilot/copilot-utils";
export default {
  state: () => ({
    rules: [
      {
        text: i18n.t("TXT_RESTRICT_BY_WEEKDAYS"),
        isActive: true,
        idCondition: 0,
        numberOrder: 0,
        conditionType: 0,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_LIMIT_PRICE_BY_COMPETITOR"),
        isActive: true,
        idCondition: 0,
        numberOrder: 4,
        conditionType: 1,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_CONTRIBUTION_MARGIN_LIMIT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 9,
        conditionType: 2,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_INCREMENT_DECREMENT_PRICE"),
        isActive: true,
        idCondition: 0,
        numberOrder: 3,
        conditionType: 3,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_USE_COMPETITOR_PRICE"),
        isActive: true,
        idCondition: 0,
        numberOrder: 1,
        conditionType: 4,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_GENERIC"),
        isActive: true,
        idCondition: 0,
        numberOrder: 5,
        conditionType: 6,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_09"),
        isActive: true,
        idCondition: 0,
        numberOrder: 6,
        conditionType: 7,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_99"),
        isActive: true,
        idCondition: 0,
        numberOrder: 7,
        conditionType: 8,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_50"),
        isActive: true,
        idCondition: 0,
        numberOrder: 8,
        conditionType: 9,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_LAST_PRICE"),
        isActive: true,
        idCondition: 0,
        numberOrder: 1,
        conditionType: 10,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_90"),
        isActive: true,
        idCondition: 0,
        numberOrder: 8,
        conditionType: 11,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_10"),
        isActive: true,
        idCondition: 0,
        numberOrder: 8,
        conditionType: 12,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_LAST_CENT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 13,
        conditionType: 13,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_PROFIT_VARIATION_LOWER"),
        isActive: true,
        idCondition: 0,
        numberOrder: 14,
        conditionType: 14,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_COMPETITIVE_INDEX"),
        isActive: true,
        idCondition: 0,
        numberOrder: 15,
        conditionType: 15,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_CENT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 16,
        conditionType: 16,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_VARIATION_DEMAND"),
        isActive: true,
        idCondition: 0,
        numberOrder: 17,
        conditionType: 17,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_DEMAND_X_STOCK"),
        isActive: true,
        idCondition: 0,
        numberOrder: 18,
        conditionType: 18,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_PRICE_SALE_VARIATION"),
        isActive: true,
        idCondition: 0,
        numberOrder: 19,
        conditionType: 19,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_BILLING_VARIATION"),
        isActive: true,
        idCondition: 0,
        numberOrder: 20,
        conditionType: 20,
        showWithResource: "all",
      },

      {
        text: i18n.t("TXT_RULECONDITION_MINIMUMMARGINNETWORK"),
        isActive: true,
        idCondition: 0,
        numberOrder: 21,
        conditionType: 21,
        showWithResource: "rule.minimum.network.margin",
      },
      {
        text: i18n.t("TXT_RULECONDITION_PRICELIMIT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 22,
        conditionType: 22,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_PRICEMULTIPLIER"),
        isActive: true,
        idCondition: 0,
        numberOrder: 23,
        conditionType: 23,
        showWithResource: "rule.pricemultiplier",
      },
      {
        text: i18n.t("TXT_RULECONDITION_STOCKMIN"),
        isActive: true,
        idCondition: 0,
        numberOrder: 24,
        conditionType: 24,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_PRODUCTTURNOVERLIMIT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 25,
        conditionType: 25,
        showWithResource: "rule.productturnoverlimit",
      },
      {
        text: i18n.t("TXT_RULECONDITION_FLAGSHIPPRODUCT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 26,
        conditionType: 26,
        showWithResource: "rule.flagshipproduct",
      },
    ],

    ruleList: [],
    groupRules: [],
    sellerList: [],
    changedIdRules: [],
    priceLimitRuleReferenceItems: [],
    branchList: [],

    sellersListFlag: false,
    allowMultipleBranchs: false,

    skeletonListLoading: false,
    ruleListLoading: false,
    sellerListLoading: false,
  }),

  getters: {
    getRules: (state) => {
      return state.rules;
    },
    getRuleNameByConditionType: (state) => (conditionType) => {
      const rules = state.rules;
      const foundedRule = rules.find(
        (rule) => rule.conditionType == conditionType
      );

      return foundedRule?.text ?? "-";
    },
    getConditionsByIdRule: (state) => (idRule) => {
      const group =
        state.groupRules.find((group) => group.idRule == idRule) ?? null;
      return group?.conditions ?? [];
    },
    getSellerList: (state) => {
      return state.sellerList;
    },
    getGroupRuleByIdRule: (state) => (idRule) => {
      return state.groupRules.find((group) => group.idRule == idRule) ?? null;
    },
    getNameAndConditionType: (state) => {
      return state.rules.map((rule) => ({
        text: rule.text,
        conditionType: rule.conditionType,
      }));
    },
    getSellersListFlag: (state) => {
      return state.sellersListFlag;
    },
    getPriceLimitRuleReferenceItems: (state) => {
      return state.priceLimitRuleReferenceItems;
    },
    selectedBranchsToEdit: (state) => {
      return state.branchList.filter((el) => el.selected);
    },
  },

  actions: {
    async getPriceRulesConfigurations({ commit, rootGetters }) {
      const idCompany = rootGetters.userData.idCompany;
      try {
        const response = await Service.getPriceRulesConfigurations(idCompany);
        commit("updatePriceLimitRuleReferenceItems", response.data);
      } catch (error) {
        console.log(
          i18n.t("TXT_ERROR_GET_PRICING_RULES_CONFIGURATIONS", { idCompany }),
          error
        );
        commit(
          "snackbarV2/set",
          {
            message: i18n.t("TXT_ERROR_GET_PRICING_RULES_CONFIGURATIONS", {
              idCompany,
            }),
            type: "error",
          },
          { root: true }
        );
      }
    },

    async getRules({ rootState, commit, state }, payload) {
      if (payload?.fromCopilot) state.skeletonListLoading = true;
      else state.ruleListLoading = true;

      let oldRuleList = state.ruleList;

      try {
        const idCompany = rootState?.userStore?.idCompany ?? null;
        let response = await Service.getRules(idCompany, 0, 10000);
        let newRuleList = response?.data?.answer?.answer ?? [];

        if (payload?.fromCopilot) {
          const newRules = compareRuleList(oldRuleList, newRuleList);
          const changedIds = changedRuleIds(newRules, payload.newOperations);
          commit("updateChangedRules", changedIds);
        }

        commit("insertRuleList", newRuleList);
      } catch (error) {
        console.log("erro ao buscar regras", error);
        commit(
          "snackbarV2/set",
          { message: "Erro ao buscar regras", type: "error" },
          { root: true }
        );
      }

      state.ruleListLoading = false;
      state.skeletonListLoading = false;
    },

    addRule({ getters }, { idRule, conditionType }) {
      const group = getters.getGroupRuleByIdRule(idRule);
      group.addRule(conditionType);
    },

    removeRule({ getters }, { idRule, index, branch }) {
      if (branch) {
        const foundedBranch = getters.selectedBranchsToEdit.find(
          (el) => el.value === branch.value
        );

        foundedBranch.conditions
          .splice(index, 1)
          .forEach((el, index) => [(el.numberOrder = index)]);
      } else {
        const group = getters.getGroupRuleByIdRule(idRule);
        group.removeRule(index);
      }
    },

    updateSequenceRules({ getters }, { idRule, updatedList, branch }) {
      if (branch) {
        const foundedBranch = getters.selectedBranchsToEdit.find(
          (el) => el.value === branch.value
        );

        foundedBranch.conditions = updatedList;
      } else {
        const group = getters.getGroupRuleByIdRule(idRule);

        group.updateSequenceRules(updatedList);
      }
    },

    updateConditions(
      { getters },
      { idRule, key, conditionType, index, values, branch }
    ) {
      if (branch) {
        const foundedBranch = getters.selectedBranchsToEdit.filter(
          (el) => el.value == branch.value
        );

        let foundedItem = foundedBranch[0].conditions.find(
          (el, indexCondition) =>
            el.conditionType == conditionType && index == indexCondition
        );

        if (key == "updateLimitWeekDays") {
          let currentDays = [];
          values.forEach((day) => {
            currentDays.push({
              idConditionValue: 0,
              idRuleCondition: 0,
              stringValue: day,
            });
          });
          foundedItem.values = currentDays;
          return;
        }

        foundedItem.values = [];

        if (key == "updatePsycologicPriceConditions") {
          foundedItem.values = values;
        }

        values.forEach((item) => {
          foundedItem.values.push({
            ...item,
            idConditionValue: item.idConditionValue ?? 0,
          });
        });
      } else {
        const group = getters.getGroupRuleByIdRule(idRule);

        group[key](conditionType, index, values);
      }
    },

    updateSequenceGroupRules({ commit }, updatedList) {
      commit("updateSequenceGroupRules", updatedList);
    },

    setAllowMultipleBranchs({ commit }, value) {
      if (!value) commit("setBranchsToEdit", []);
      commit("setAllowMultipleBranchs", value);
    },

    updateBranchRules({ getters }, { idRule, conditionType, branchValue }) {
      let currentBranch = getters.selectedBranchsToEdit.filter(
        (el) => el.value == branchValue
      );

      currentBranch[0].conditions.push({
        conditionType: conditionType, // Tipo da regra, preço psicologico, limitar dias da semana etc
        idCondition: 0, // Criando 0, se não é o id que vem
        isActive: true,
        idRule: idRule || 0,
        numberOrder: currentBranch[0].conditions?.length, // Posição dela entre as regras
        values: [],
      });
    },
  },

  mutations: {
    updateSellerList(state, newList) {
      state.sellerList = newList;
    },
    updateSellerListFlag(state) {
      state.sellersListFlag = !state.sellersListFlag;
    },
    insertGroupRules(state, newGroupRule) {
      state.groupRules.push(newGroupRule);
    },
    removeGroupRules(state, { idRule }) {
      const index = state.groupRules.findIndex((rule) => rule.idRule == idRule);
      if (index != -1) state.groupRules.splice(index, 1);
    },
    insertRuleList(state, payload) {
      state.ruleList = payload;
    },
    updateSellerListLoading(state, status) {
      state.sellerListLoading = status;
    },
    updateChangedRules(state, payload) {
      state.changedIdRules = payload;
    },
    updateSequenceGroupRules(state, updatedList) {
      state.ruleList = updatedList;
    },
    updatePriceLimitRuleReferenceItems(state, response) {
      const getBody = (item) => ({
        value: item.value,
        description: item.description.startsWith("TXT_")
          ? i18n.t(item.description)
          : item.description,
      });

      const items = response.result
        .filter((item) => item.configurationType == 0)
        .map((item) => getBody(item));

      state.priceLimitRuleReferenceItems = items ?? [];
    },
    setAllowMultipleBranchs(state, value) {
      state.allowMultipleBranchs = value;
    },
    setBranchList(state, value) {
      let branchValues = value[0]?.values ?? [];

      state.branchList = branchValues.map((el) => ({
        value: el.value,
        description: el.description,
        selected: false,
        showInTabs: false,
        idRule: 0,
        conditions: [],
      }));
    },
    setBranchsToEdit(state, selectedBranchs) {
      const selectedValues = new Set(selectedBranchs.map((el) => el.value));
      state.branchList.forEach((branch) => {
        branch.selected = selectedValues.has(branch.value);
      });
    },
    setBranchsToEdit2(state, selectedBranchs) {
      const selectedValues = new Set(
        selectedBranchs.childrens.map((el) => el.filters[0].value)
      );

      state.branchList.forEach((branch) => {
        if (selectedValues.has(branch.value)) {
          const child = selectedBranchs.childrens.find(
            (el) => el.filters[0].value === branch.value
          );
          if (child) {
            branch.selected = true;
            branch.showInTabs = true;
            branch.conditions = child.conditions;
            branch.idRule = child.idRule;
          }
        } else {
          branch.selected = false;
          branch.showInTabs = false;
          branch.idRule = 0;
          branch.conditions = [];
        }
      });
    },
  },
};
