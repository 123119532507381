<template>
  <div class="rules-main">
    <Group-Rule-list-main @openCopilot="openCopilot" />

    <transition name="slide">
      <Copilot-main v-if="copilotIsOpen" />
    </transition>
  </div>
</template>

<script>
import GroupRuleListMain from "@/views/main/pricing-rules-v2/group-rule-list-main.vue";
import CopilotMain from "../copilot/copilot-main.vue";

export default {
  components: { CopilotMain, GroupRuleListMain },

  computed: {
    copilotIsOpen() {
      return this.$store?.state?.copilotStore?.isOpen ?? false;
    },
  },

  methods: {
    openCopilot() {
      this.$store.commit("TOGGLE_COPILOT_MODAL", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.title-destaq {
  border-bottom: 3px solid #ec4c37;
}

.rules-main {
  display: flex;
  gap: 0.5rem;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.25s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
