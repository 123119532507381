<template>
  <div>
    <v-row no-gutters justify="space-between" align="center" class="mt-4">
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <Prd-combo-box
            v-model="currentRule"
            :items="filteredRuleList"
            :title="$i18n.t('TXT_RULES')"
          />
        </v-col>
        <v-icon
          @click="addRule"
          :disabled="!currentRule"
          :color="$prdStyles('color-success')"
          size="30"
          class="mt-6 ml-4"
          >mdi-plus-box</v-icon
        >
      </v-row>
      <Prd-btn
        v-if="branch"
        :color="$prdStyles('color-warning')"
        :title="$t('TXT_DELETE_BRANCH')"
        @click="removeBranch"
        outlined
        class="mt-7 mr-4"
      />

      <Add-branchs-modal
        v-if="branch"
        :branch="branch"
        @handleChooseBranchs="replicateBranch"
        replicateBranchs
      />
    </v-row>
    <Rule-item-panels
      :ruleConditions="ruleConditions"
      @updateConditionValues="updateConditionValues"
      @removeRuleFromList="removeRuleFromList"
      @switchRule="switchRule"
      @handleChangeRulePosition="handleChangeRulePosition"
    />
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import RuleItemPanels from "./components/rule-item-panels.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import AddBranchsModal from "./add-branchs-modal.vue";

export default {
  components: {
    PrdComboBox,
    RuleItemPanels,
    PrdBtn,
    AddBranchsModal,
  },

  props: {
    rule: {
      type: Object,
    },
    ruleList: {
      type: Array,
    },
    branch: {
      type: Object,
    },
  },

  data() {
    return {
      currentRule: null,
    };
  },

  computed: {
    resourceList() {
      return this.$store.getters.resources;
    },

    ruleConditions() {
      return this.branch ? this.branch.conditions : this.rule.conditions;
    },

    filteredRuleList() {
      return this.ruleList.filter(
        (rule) =>
          !this.ruleConditions.some(
            (condition) => condition.conditionType === rule.conditionType
          )
      );
    },
  },

  watch: {
    rule: {
      handler(rule) {
        rule.conditions;
      },
    },
  },

  methods: {
    addRule() {
      this.$emit("addRule", this.currentRule, this.branch);
      this.currentRule = null;
    },

    removeBranch() {
      this.$emit("removeBranch", this.branch);
    },

    removeRuleFromList(rule) {
      this.$emit("removeRuleFromList", rule, this.branch);
    },

    switchRule(rule) {
      this.$emit("switchRule", rule, this.branch);
    },

    handleChangeRulePosition(rules) {
      this.$emit("handleChangeRulePosition", rules, this.branch);
    },

    updateConditionValues(conditions) {
      this.$emit("updateConditionValues", {
        ...conditions,
        branch: this.branch,
      });
    },

    replicateBranch(selectedBranchs) {
      this.$emit("replicateBranch", {
        selectedBranchs,
        rule: this.rule,
        branch: this.branch,
      });
    },
  },
};
</script>
