<template>
  <div>
    <v-icon @click="openModal" color="green" size="30px"> mdi-plus-box </v-icon>

    <v-dialog v-model="isModalOpen" v-if="isModalOpen" :width="500" persistent>
      <v-card class="pa-4">
        <v-row no-gutters align="center" justify="space-between">
          <h4>ADICIONAR FILIAIS</h4>
          <v-icon @click="cancelChanges">mdi-close</v-icon>
        </v-row>
        
        <Prd-Combo-Box
          v-model="selectedBranchs"
          :items="branchList"
          title="Escolha uma ou mais filiais"
          item-text="description"
          item-value="value"
          multiple
          small-chips
          deletable-chips
          chips
          clear-icon
          class="mt-6"
        />

        <v-row no-gutters align="center" justify="end" class="mt-4">
          <Prd-Btn
            @click="cancelChanges"
            title="Cancelar"
            outlined
            class="mr-4"
          />
          <Prd-Btn @click="handleChooseBranchs" title="Confirmar" />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
export default {
  components: { PrdBtn, PrdComboBox },

  props: {
    branchList: {
      type: Array,
    },
  },

  data: () => ({
    isModalOpen: false,
    selectedBranchs: [],
  }),

  methods: {
    openModal() {
      this.branchList.forEach((el) => {
        if (el.selected) {
          console.log('aqui', el);
          this.selectedBranchs.push(el);
        }

      });
      this.isModalOpen = true;
    },

    cancelChanges() {
      this.selectedBranchs = [];
      this.isModalOpen = false;
    },

    handleChooseBranchs() {
      this.$store.commit("setBranchsToEdit", this.selectedBranchs);
      this.cancelChanges();
    },
  },
};
</script>
