import service from "@/service/pricing-rules/index.js";
const Service = new service();

import createService from "@/service/pricing-rules/create-rules.js";
const CreateService = new createService();
export default {
  state: () => ({
    groupRulesList: [],
    ruleFilters: [],
    branchList: [],
    sellerList: [],

    ruleListLoading: false,
    ruleFiltersLoading: false,
    sellerListLoading: false,
  }),

  getters: {},

  mutations: {
    updateRuleList(state, payload) {
      state.groupRulesList = payload;
    },

    updateRuleStatus(state, idRule) {
      const foundedRule = state.groupRulesList.find(
        (el) => el.idRule == idRule
      );
      foundedRule.isActive = !foundedRule.isActive;
    },

    updateRuleWithData(state, payload) {
      const index = state.groupRulesList.findIndex(
        (el) => el.idRule === payload.idRule
      );

      if (index !== -1) {
        state.groupRulesList.splice(index, 1, payload);
      }
    },

    updateSellerList(state, payload) {
      state.sellerList = payload;
    },

    updateRuleFilters(state, payload) {
      state.ruleFilters = payload;
    },

    updateBranchList(state, payload) {
      state.branchList = payload;
    },

    removeRuleByid(state, payload) {
      state.groupRulesList = state.groupRulesList.filter(
        (el) => el.idRule !== payload
      );
    },
  },

  actions: {
    async getRules({ rootState, state, commit }, showLoader) {
      if (showLoader) state.ruleListLoading = true;

      try {
        const idCompany = rootState?.userStore?.idCompany ?? null;
        let response = await Service.getRules(idCompany, 0, 10000);
        commit("updateRuleList", response?.data?.answer?.answer ?? []);
      } catch (error) {
        this.$handleError(error, "Erro ao buscar regras");
      }

      state.ruleListLoading = false;
    },

    async getFilters({ rootState, state, commit }) {
      state.ruleFiltersLoading = true;

      try {
        const idCompany = rootState?.userStore?.idCompany ?? null;
        let response = await CreateService.getFilters(idCompany);

        commit("updateRuleFilters", response?.data?.result ?? []);
        commit(
          "updateBranchList",
          response?.data?.result.filter((el) => el.dbField == 7)
        );
      } catch (error) {
        this.$handleError(error, "Erro ao buscar filtros");
      }

      state.ruleFiltersLoading = false;
    },

    async getSellers({ rootState, state, commit }) {
      state.sellerListLoading = true;

      try {
        const idCompany = rootState?.userStore?.idCompany ?? null;

        let response = await CreateService.getCompetitors(idCompany);
        commit("updateSellerList", response?.data?.result ?? []);
      } catch (error) {
        this.$handleError(error, "Erro ao buscar concorrentes");
      }

      state.sellerListLoading = false;
    },
  },
};
