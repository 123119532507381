import i18n from "@/i18n";

export const ruleList = [
  {
    text: i18n.t("TXT_RESTRICT_BY_WEEKDAYS"),
    conditionType: 0,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_LIMIT_PRICE_BY_COMPETITOR"),
    conditionType: 1,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_CONTRIBUTION_MARGIN_LIMIT"),
    conditionType: 2,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_INCREMENT_DECREMENT_PRICE"),
    conditionType: 3,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_USE_COMPETITOR_PRICE"),
    conditionType: 4,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_RULECONDITION_ROUNDING_GENERIC"),
    conditionType: 6,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_RULECONDITION_ROUNDING_09"),
    conditionType: 7,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_RULECONDITION_ROUNDING_99"),
    conditionType: 8,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_RULECONDITION_ROUNDING_50"),
    conditionType: 9,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_RULECONDITION_LAST_PRICE"),
    conditionType: 10,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_RULECONDITION_ROUNDING_90"),
    conditionType: 11,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_RULECONDITION_ROUNDING_10"),
    conditionType: 12,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_RULECONDITION_ROUNDING_LAST_CENT"),
    conditionType: 13,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_PROFIT_VARIATION_LOWER"),
    conditionType: 14,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_COMPETITIVE_INDEX"),
    conditionType: 15,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_RULECONDITION_ROUNDING_CENT"),
    conditionType: 16,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_VARIATION_DEMAND"),
    conditionType: 17,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_DEMAND_X_STOCK"),
    conditionType: 18,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_PRICE_SALE_VARIATION"),
    conditionType: 19,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_BILLING_VARIATION"),
    conditionType: 20,
    showWithResource: "all",
  },

  {
    text: i18n.t("TXT_RULECONDITION_MINIMUMMARGINNETWORK"),
    conditionType: 21,
    showWithResource: "rule.minimum.network.margin",
  },
  {
    text: i18n.t("TXT_RULECONDITION_PRICELIMIT"),
    conditionType: 22,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_RULECONDITION_PRICEMULTIPLIER"),
    conditionType: 23,
    showWithResource: "rule.pricemultiplier",
  },
  {
    text: i18n.t("TXT_RULECONDITION_STOCKMIN"),
    conditionType: 24,
    showWithResource: "all",
  },
  {
    text: i18n.t("TXT_RULECONDITION_PRODUCTTURNOVERLIMIT"),
    conditionType: 25,
    showWithResource: "rule.productturnoverlimit",
  },
  {
    text: i18n.t("TXT_RULECONDITION_FLAGSHIPPRODUCT"),
    conditionType: 26,
    showWithResource: "rule.flagshipproduct",
  },
];

export function buildRequest(filters, rule, idCompany) {
  console.log(rule);
  
  return {
    allowAbcCurve: filters.curve || "ABC",
    description: rule.description,
    enableMultGroups: rule.enableMultGroups || false,
    conditions: buildConditionsRequest(rule.conditions),
    children: rule.children || [],
    enterprise_ElasticityStatus: filters?.elasticity?.value ?? 0,
    filters: buildFilterRequest(filters.filters),
    idCompany: idCompany,
    idRule: rule.idRule || 0,
    isActive: rule.isActive || true,
    isAllProducts: rule.isAllProducts || false,
    numberOrder: rule.numberOrder || 0,

    isSimulation: false,
    enterprisePricingType: 0,
    isBlocked: false,
    categories: [],
    products: [],
  };
}
const buildConditionsRequest = (conditions) => {
  return conditions.map((el, index) => ({
    ...el,
    numberOrder: index + 1,
  }));
};
const buildFilterRequest = (filters) => {
  return filters
    .filter((el) => el.showFilter && el?.selected?.value)
    .map((el) => ({
      idEnterprisePriceGroup_DefaultFilter:
        el.idEnterprisePriceGroupDefaultFilter,
      value: el?.selected?.value,
    }));
};

export function findConditionIndex (rule, conditions) {
  return conditions.findIndex((el) => el.conditionType === rule.conditionType)
}

export function findBranchInRule(branch,branchs){
  return branchs.find(
    (child) => child.filters[0].value === branch
  );
}
