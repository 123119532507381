<template>
  <div>
    <v-icon
      v-if="!replicateBranchs"
      @click="openModal"
      color="green"
      size="30px"
    >
      mdi-plus-box
    </v-icon>
    <Prd-Btn
      v-else
      @click="openModal"
      :title="$i18n.t('TXT_REPLICATE_BRANCH')"
      class="mt-7"
    />

    <v-dialog v-model="isModalOpen" v-if="isModalOpen" :width="500" persistent>
      <v-card class="pa-4">
        <v-row no-gutters align="center" justify="space-between">
          <h4 v-if="replicateBranchs">
            {{ $i18n.t("TXT_REPLICATE_RULES_TO_BRANCH") }}
          </h4>

          <h4 v-else>{{ $i18n.t("TXT_ADD_BRANCHS") }}</h4>

          <v-icon @click="cancelChanges">mdi-close</v-icon>
        </v-row>

        <p v-if="replicateBranchs" class="my-6">
          {{ $i18n.t("TXT_REPLICATE_BRANCH_CONFIRM") }}
          <span class="font-weight-bold">{{ branchName }}</span>
          {{ $i18n.t("TXT_TO_ANOTHER") }}
        </p>

        <Prd-Combo-Box
          v-model="selectedBranchs"
          :items="localBranchList"
          :title="$i18n.t('TXT_CHOOSE_ONE_MORE_BRANCHS')"
          item-text="description"
          item-value="value"
          multiple
          small-chips
          deletable-chips
          chips
          clear-icon
          class="mt-6"
        />
        <v-row no-gutters align="center" justify="end" class="mt-4">
          <Prd-Btn
            @click="cancelChanges"
            :title="$i18n.t('TXT_CANCEL')"
            outlined
            class="mr-4"
          />
          <Prd-Btn
            @click="handleChooseBranchs"
            :title="$i18n.t('TXT_CONFIRM')"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
export default {
  components: { PrdBtn, PrdComboBox },
  props: {
    rule: {
      type: Object,
      required: false,
    },

    branch: {
      type: Object,
      required: false,
    },

    replicateBranchs: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isModalOpen: false,
    selectedBranchs: [],
    localBranchList: [],
  }),

  computed: {
    branchList() {
      return this.$store.state?.pricingRuleV2?.branchList[0]?.values ?? [];
    },
    branchName() {
      return this.branch?.filters[0]?.value ?? "-";
    },
  },

  methods: {
    openModal() {
      if (!this.replicateBranchs) {
        this.localBranchList = structuredClone(this.branchList);

        if (this.rule.children.length) this.fillBranchList();
      } else {
        this.localBranchList = this.branchList.filter(
          (item) => item.value !== this.branch.filters[0].value
        );
      }

      this.isModalOpen = true;
    },

    fillBranchList() {
      this.selectedBranchs = this.rule.children
        .filter((el) => Array.isArray(el.filters) && el.filters[0])
        .map((el) => ({
          value: el.filters[0].value,
          description: el.filters[0].value,
        }));
    },

    handleChooseBranchs() {
      this.$emit("handleChooseBranchs", this.selectedBranchs);
      this.cancelChanges();
    },

    cancelChanges() {
      this.selectedBranchs = [];
      this.isModalOpen = false;
    },
  },
};
</script>
