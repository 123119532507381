import { render, staticRenderFns } from "./rule-item-panels.vue?vue&type=template&id=684edf30&scoped=true"
import script from "./rule-item-panels.vue?vue&type=script&lang=js"
export * from "./rule-item-panels.vue?vue&type=script&lang=js"
import style0 from "./rule-item-panels.vue?vue&type=style&index=0&id=684edf30&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684edf30",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanels})
