<template>
  <div class="mt-4">
    <v-row no-gutters>
      <v-col cols="12" md="2" class="mr-4">
        <Prd-text-field
          :title="$t('TXT_ADJUST_PRICE')"
          v-model="model.Value"
          :type="'number'"
          hide-spin-buttons
          :min="0"
        />
      </v-col>

      <v-col>
        <p class="mb-2 mt-1">{{ $t("TXT_ADJUST_TYPE") }}</p>
        <v-radio-group
          v-model="model.IsPercentage"
          row
          dense
          class="ma-0 pa-0"
          hide-details
        >
          <v-radio
            :color="$prdStyles('color-primary')"
            :label="'R$'"
            :value="false"
          ></v-radio>
          <v-radio
            :color="$prdStyles('color-primary')"
            :label="'%'"
            :value="true"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  props: ["index", "values", "idRule","branch"],
  components: { PrdTextField },
  data() {
    return {
      model: {
        Value: null,
        IsPercentage: false,
      },
    };
  },

  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    values: {
      handler(value) {
        if (value.values?.length > 0) this.setInputs(value.values);
      },
      immediate: true,
    },
  },
  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          decimalValue: inputs.Value ? parseFloat(inputs.Value) : 0,
          booleanValue: false,
          stringValue: "Value",
        },
        {
          booleanValue: inputs.IsPercentage,
          stringValue: "IsPercentage",
          decimalValue: 0,
        },
      ];
      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updateConditionsDefault",
        conditionType: 3,
        index: this.index,
        values: formatedValue,
        branch: this.branch

      });
    },
    setInputs(conditionList) {
      const Value = conditionList.find(
        (condition) => condition.stringValue == "Value"
      ).decimalValue;
      this.model.Value = Value;

      const IsPercentage = conditionList.find(
        (condition) => condition.stringValue == "IsPercentage"
      ).booleanValue;
      this.model.IsPercentage = IsPercentage;
    },
  },
};
</script>