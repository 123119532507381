<template>
  <v-row no-gutters>
    <v-checkbox
      v-model="model.IgnoreIfNull"
      :label="$t('TXT_IGNORE_RULE_IF_NO_FLAGSHIP_PRODUCT')"
      dense
      hide-details
    />
  </v-row>
</template>

<script>
export default {
  props: ["index", "values", "idRule","branch"],
  data() {
    return {
      model: {
        IgnoreIfNull: false,
      },
    };
  },
  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    values: {
      handler(value) {
        if (value.values?.length > 0) this.setInputs(value.values);
        else
          this.model.reference = {
            text: `${this.$i18n.t("TXT_VARIATION")} ${this.$i18n.t(
              "TXT_BIGGER_THEN"
            )}`,
            value: 0,
          };
      },
      immediate: true,
    },
  },
  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "IgnoreIfNull",
          decimalValue: 0,
          booleanValue: inputs.IgnoreIfNull,
        },
      ];

      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updateConditionsDefault",
        conditionType: 26,
        index: this.index,
        values: formatedValue,
        branch: this.branch

      });
    },
    setInputs(conditionList) {
      const IgnoreIfNull = conditionList.find(
        (condition) => condition.stringValue == "IgnoreIfNull"
      ).booleanValue;
      this.model.IgnoreIfNull = IgnoreIfNull;
    },
  },
};
</script>
