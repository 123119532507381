<template>
  <div>
    <v-row no-gutters class="gap-4">
      <v-col cols="auto" align-self="end">
        <v-checkbox
          v-model="model.UseManualLimit"
          hide-details
          :label="$t('TXT_MANUAL_LIMIT')"
        />
      </v-col>
      <v-col cols="auto">
        <Prd-currency-field
          v-model="model.ManualLimitValue"
          @update="model.ManualLimitValue = $event"
          :title="$t('TXT_MANUAL_VALUE')"
          :disabled="!model.UseManualLimit"
          :filled="!model.UseManualLimit"
        />
      </v-col>
      <v-col cols="auto">
        <Prd-combo-box
          v-model="model.FieldReference"
          :items="referenceItems"
          :disabled="model.UseManualLimit"
          :filled="model.UseManualLimit"
          :title="$t('TXT_REFERENCE_FIELD')"
          :itemText="'description'"
          :label="fieldReferenceLabel"
        />
      </v-col>
    </v-row>
    <v-checkbox
      v-model="model.AdjustPrice"
      :label="$t('TXT_AUTOMATIC_PRICE_ADJUST')"
      hide-details
    />
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdCurrencyField from "@/components/common/prd-currency-field.vue";

export default {
  props: ["index", "values", "idRule", "branch"],
  components: { PrdComboBox, PrdCurrencyField },
  data() {
    return {
      model: {
        UseManualLimit: false,
        ManualLimitValue: null,
        FieldReference: null,
        AdjustPrice: false,
      },
    };
  },
  computed: {
    referenceItems() {
      return this.$store.getters.getPriceLimitRuleReferenceItems ?? [];
    },
    fieldReferenceLabel() {
      let translation = " ";

      if (!this.referenceItems.length) translation = "TXT_NO_AMOUNT_RELEASED";
      if (!this.model.UseManualLimit) translation = "TXT_SELECT";

      return this.$i18n.t(translation);
    },
  },
  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    "model.UseManualLimit": {
      handler(value) {
        this.toggleUseManualLimit(value);
      },
      immediate: true,
    },
  },

  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "UseManualLimit",
          decimalValue: 0,
          booleanValue: inputs.UseManualLimit,
        },
        {
          stringValue: "ManualLimitValue",
          decimalValue: parseFloat(inputs.ManualLimitValue) ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "FieldReference",
          decimalValue: parseFloat(inputs.FieldReference?.value) ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "AdjustPrice",
          decimalValue: 0,
          booleanValue: inputs.AdjustPrice,
        },
      ];

      this.$emit("updateConditionValues", { formatedValue, conditionType: 22 });
    },

    setInputs(conditionList) {
      const UseManualLimit = conditionList.find(
        (condition) => condition.stringValue == "UseManualLimit"
      ).booleanValue;
      this.model.UseManualLimit = UseManualLimit;

      const ManualLimitValue = conditionList.find(
        (condition) => condition.stringValue == "ManualLimitValue"
      ).decimalValue;
      this.model.ManualLimitValue = ManualLimitValue;

      const FieldReference = conditionList.find(
        (condition) => condition.stringValue == "FieldReference"
      ).decimalValue;
      this.model.FieldReference =
        this.referenceItems.find((item) => item.value == FieldReference) ??
        null;

      const AdjustPrice = conditionList.find(
        (condition) => condition.stringValue == "AdjustPrice"
      ).booleanValue;
      this.model.AdjustPrice = AdjustPrice;
    },

    toggleUseManualLimit(value) {
      if (!value) {
        this.model.ManualLimitValue = 0;
      } else {
        this.model.FieldReference = null;
      }
    },
  },

  created() {
    if (this.values.length > 0) this.setInputs(this.values);
  },
};
</script>
