<template>
  <div class="my-8">
    <h4>{{ $t("TXT_FILTERS_LOWERCASE") }}</h4>

    <Prd-loading-msg
      v-if="ruleFiltersLoading"
      :text="$t('TXT_LOADING_FILTERS')"
      :messageInFront="true"
      class="mt-6"
    />
    <div v-else class="mt-4">
      <v-row no-gutters>
        <v-col
          v-for="filter in localFilters"
          :key="filter.dbField"
          v-show="filter.showFilter"
          cols="12"
          md="3"
          class="mb-4"
        >
          <Prd-combo-box
            v-model="filter.selected"
            :title="setTranslations(filter.fieldName)"
            :items="filter.values"
            :disabled="filter.values.length == 0"
            :filled="filter.values.length == 0"
            :textTooltipInformation="filter.tooltip"
            :useTooltipInformation="filter.tooltip"
            :itemText="'description'"
            :itemValue="'value'"
            class="mr-4"
          />
        </v-col>
        <v-col v-if="!ruleFiltersLoading" cols="12" md="3">
          <Prd-combo-box
            v-model="curve"
            :title="$t('TXT_ABC_ANALYSIS')"
            :items="abcItems"
            class="mr-4"
          />
        </v-col>
        <v-col v-if="!ruleFiltersLoading" cols="12" md="3">
          <Prd-combo-box
            v-model="elasticity"
            :title="$t('TXT_ELASTICITY')"
            :items="elasticityItems"
            class="mr-4"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";

import { setTranslations } from "@/utils/setTranslations";

export default {
  components: { PrdLoadingMsg, PrdComboBox },
  props: {
    rule: {
      type: Object,
    },
    isCreateRule: {
      type: Boolean,
    },
  },
  data() {
    return {
      localFilters: [],
      abcItems: ["ABC", "AB", "AC", "BC", "A", "B", "C"],
      elasticityItems: [
        {
          text: `${this.$i18n.t("TXT_ELASTIC_2")} / ${this.$i18n.t(
            "TXT_INELASTIC_2"
          )}`,
          value: 0,
        },
        {
          text: this.$i18n.t("TXT_ELASTIC_2"),
          value: 1,
        },
        {
          text: this.$i18n.t("TXT_INELASTIC_2"),
          value: 2,
        },
      ],

      curve: null,
      elasticity: null,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    ruleFilters() {
      return this.$store.state.pricingRuleV2.ruleFilters;
    },
    ruleFiltersLoading() {
      return this.$store.state.pricingRuleV2.ruleFiltersLoading;
    },
    VEM() {
      return this.idCompany == 3026;
    },
  },

  watch: {
    ruleFilters: {
      handler(value) {
        if (value.length) this.transformFilters(value);
      },
      deep: true,
      immediate: true,
    },

    rule: {
      handler(value) {
        if (value && this.ruleFilters.length) this.setSelectedValues(value);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    setTranslations,

    transformFilters(filters) {
      this.localFilters = filters
        .map((filter) => ({
          ...filter,
          showFilter: !(this.enableMultGroups && filter.dbField === 7),
          selected: null,
        }))
        .filter((filter) => filter.fieldName !== "Curva ABC");
    },

    setSelectedValues(rule) {
      if (this.VEM) this.filterBranchFilter(rule.enableMultGroups);

      this.setFilters(rule.filters);

      this.setFiltersDefault(
        rule.allowAbcCurve,
        rule.enterprise_ElasticityStatus
      );
    },

    setFilters(filters) {
      if (!filters) return;

      filters.forEach((filter) => {
        const matchingFilter = this.localFilters.find(
          (localFilter) =>
            localFilter.idEnterprisePriceGroupDefaultFilter ==
            filter.idEnterprisePriceGroup_DefaultFilter
        );

        if (matchingFilter) {
          matchingFilter.selected = filter.value;
        }
      });
    },

    setFiltersDefault(abcCurve, elasticity) {
      this.curve = abcCurve;
      this.elasticity = this.elasticityItems.find(
        (el) => el.value == elasticity
      );
    },

    filterBranchFilter(value) {
      let branchFilter = this.localFilters.filter((el) => el.dbField == 7);
      branchFilter[0].showFilter = !value;
    },

    returnFilters() {
      return {
        curve: this.curve,
        elasticity: this.elasticity,
        filters: this.localFilters,
      };
    },
  },
};
</script>
